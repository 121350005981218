/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/03/2021, 19:16
 */
// eslint-disable-next-line
const errors = {
	/*Field errors*/
	'rod.error.be_confirmed': 'Пароли не совпадают',
	'rod.error.boolean_type': 'Временно недоступно',
	'rod.error.cap_letter_required': 'Требуется 1 прописная буква',
	'rod.error.code.invalid':
		'Неверный код. У вас {count, plural, one {осталась} few {осталось} other {осталось}} {count, number} {count, plural, one {попытка} few {попытки} other {попыток}} для ввода кода',
	'rod.error.code.limit':
		'Превышен лимит попыток. Переключитесь на ручное подписание',
	'rod.error.code.retry': 'Попробуйте позже',
	'rod.error.code.forbidden':
		'Превышено количество попыток подписания. Пожалуйста, обратитесь в поддержку',
	'rod.error.code_code_exhausted':
		'Вы истратили 3 попытки ввода верного кода. Пожалуйста, отправьте код повторно и повторите ввод',
	'rod.error.code_exhausted': 'Превышен лимит попыток. Попробуйте позже', //todo формат лексемы от бэка без code_
	'rod.error.date_format': 'Неверный формат даты',
	'rod.error.date_past': 'Дата не должна быть в прошлом',
	'rod.error.digit_required': 'Требуется 1 цифра',
	'rod.error.email_exists': 'Пользователь с таким email уже существует',
	'rod.error.exists_in_signup': 'Пользователь с таким email уже существует',
	'rod.error.email_format': 'Неверный формат электронной почты',
	'rod.error.invalid_upc_format': 'невалидный формат UPC',
	'rod.error.invalid_code_invalid':
		'Этот код недействителен — идентификатор банка не существует.',
	'rod.error.greater_than_or_equal_value':
		'Должно быть больше или равно {num, number}',
	'rod.error.incorrect_username_or_password': 'Неверный email/пароль',
	'rod.error.exists_in_signup.email': 'Такая почта уже зарегистрирована',
	'rod.error.exists_in_signup.phone': 'Такой телефон уже зарегистрирован',
	'rod.error.unique_value':
		'Данная почта уже используется. Пожалуйста, используйте другую',
	'rod.error.exists_in_user.email': 'Такая почта уже зарегистрирована',
	'rod.error.exists_in_user.phone': 'Такой телефон уже зарегистрирован',
	'rod.error.invalid': 'Неверный формат',
	'rod.error.non_unique': 'данные не уникальны',

	'rod.error.invalid_password': 'Слишком простой пароль',
	'rod.error.invalid_password_confirmation': 'Слишком простой пароль',
	'rod.error.cyrillic_invalid': 'используйте латиницу',
	'rod.error.cyrillic_invalid_password': 'используйте латиницу',
	'rod.error.integer_type': 'Можно вводить только цифры',
	'rod.error.letter_required': 'Требуется 1 строчная буква',
	'rod.error.max_length':
		'Максимальное значение {num, number} {num, plural, one {символ} few {символа} other {символов}}',
	'rod.error.max_length_no_value': 'Слишком длинное значение',
	'rod.error.min_length':
		'Минимальная длина {num, number} {num, plural, one {символ} few {символа} other {символов}}',
	//	'Минимум {vlue, number} {value,  plural, one {символ} few {символа} other {символов}}',
	'rod.error.min_length_no_value': 'Слишком короткое значение',
	'rod.error.not_present': 'Необходимо заполнить',
	'rod.error.password_invalid': 'Ваш пароль не соответствует требованиям',
	'rod.error.password_required': 'Необходимо заполнить',
	'rod.error.phone_exists': 'Пользователь с таким номером уже существует',
	'rod.error.country': 'Выберите страну из списка',
	'rod.error.required': 'обязательное поле для ввода',
	'rod.error.required_without': 'обязательное поле для ввода',
	'rod.error.image_dimensions': 'некорректный размер обложки',
	'rod.error.max_size.genres': 'Выберите не более {value} жанров',
	'rod.error.max_size.genres.fixed':
		'Превышено количество выбранных жанров. Должно быть не более трех',
	'rod.error.max_size':
		'Размер файла слишком большой. Должен быть менее {value} Mb',
	'rod.error.image_wrong_format': 'неверный формат',
	'rod.error.image_size_exceeded':
		'размер файла не должен превышать 40 мегабайт',
	'rod.error.image_minimum_size':
		'размер изображения должен быть не менее 1500 x 1500 пикселей',
	'rod.error.image_aspect_ratio': 'обложка должна иметь соотношение сторон 1:1',

	'rod.error.wrong_format': 'Неверный формат',
	'rod.error.before_or_equal_date': 'Неверный формат',
	'rod.error.time_below-1970': 'Дата не должна быть меньше 01.01.1970',
	'rod.error.max_date_value':
		'Дата начала продаж не может быть более чем 5 дней',
	'rod.error.min_date_value':
		'Дата начала продаж не может быть меньше текущей даты',
	'rod.error.check_box_outlets_required':
		'Должна быть выбрана минимум 1 витрина',
	'rod.error.review.composition': 'ошибка отправки на модерацию',
	'rod.error.required_roles':
		'В произведении должен быть хотя бы один автор слов или композитор',
	'rod.error.required_submitter':
		'Чтобы добавить произведение, необходимо контролировать хотя бы одну долю в нем',
	'rod.error.required_society': 'Такого авторского общества не существует',
	'rod.error.required_if': 'Необходимо заполнить',
	'rod.error.required_unless': 'обязательное поле для ввода',
	'rod.error.string_type': 'Необходимо заполнить',
	'rod.error.required_with': 'Необходимо заполнить',
	'rod.error.currency_country_not_match':
		'Валюта в контракте и страна банка не совпадают',

	'rod.error.min_repertoire_value':
		'Количество произведений должно быть не меньше 1',
	'rod.error.user_exist': 'Такой пользователь уже существует',
	'rod.error.user_exists': 'Пользователь уже существует',
	'rod.error.code_invalid': 'Неверный код',
	'rod.error.min_age':
		'Минимальный возраст {num, number} {num, plural, one {год} few {года} other {лет}}',
	'rod.error.min_age_no_value': 'Сервис доступен только для совершеннолетних',
	'rod.error.wrong_date': 'Дата окончания паспорта раньше даты начала',
	'rod.error.expired': 'Паспорт не действителен',
	'rod.error.contracts.add.conditions':
		'Пожалуйста, выберите хотя бы один объект договора',
	'rod.error.contracts.add.conditions_with_lyric':
		'Для осуществления сбора за использование лирики необходимо разрешить сбор за произведение хотя бы в одном из блоков выше.',
	'rod.error.maxDate':
		'Укажите оригинальную дату начала продаж (должна быть в прошлом)',
	'rod.error.minDate':
		'Дата начала продаж не может быть меньше 7 дней от текущей даты',
	'rod.error.sale.start.date.sla.one.plus':
		'Дата начала продаж не может быть меньше одного дня, начиная с текущего',
	'rod.error.sales_start_date_2_days':
		'Дата начала продаж не может быть меньше двух дней, начиная с текущего',
	'rod.error.sales_start_date_3_days':
		'Дата начала продаж не может быть меньше трех дней, начиная с текущего',
	'rod.error.statistic.minDate': 'Дата не может быть ранее ',
	'rod.error.failed_to_load_file': 'ошибка загрузки файла',
	'rod.error.failed_to_load_files': 'ошибка загрузки',
	'rod.error.load.data.error': 'ошибка загрузки данных...',
	'rod.error.validation.cyrillic': 'необходимо использовать кириллицу',
	'rod.error.validate.cyrillic': 'необходимо использовать кириллицу',
	'rod.error.validation.latin': 'Необходим ввод только латинских символов',
	'rod.error.validate.latin': 'Необходим ввод только латинских символов',

	/*Page errors*/
	'rod.error_page.denied.body': 'у вас нет прав на просмотр этих данных',
	'rod.error_page.denied.header': 'доступ запрещен',
	'rod.error_page.session_expired.header': 'сессия завершилась',
	'rod.error_page.session_expired.body':
		'Пожалуйста <a>авторизуйтесь</a> снова, чтобы продолжить',
	'rod.error_page.not_found.body': 'запрашиваемая вами страница не найдена',
	'rod.error_page.not_found.header': 'ошибка',
	'rod.error_page.unavailable.body': 'Попробуйте обновить страницу чуть позже',
	'rod.error_page.unavailable.header': 'возникла ошибка',
	'rod.error_page.unavailable.datails': 'Подробнее об ошибке',

	'rod.field.contracts.contract_error.value.valid': 'содержит не все страницы',
	'rod.field.contracts.contract_error.value.signature':
		'отсутствует подпись на всех страницах',
	'rod.field.contracts.contract_error.value.quality':
		'низкое качество документа',

	'rod.field.upload.moderation.title': 'Документ содержит ошибки',
	'rod.field.upload.moderation.title.quality': 'Документ низкого качества',
	'rod.field.upload.moderation.title.invalid': 'Документ недействителен',
	'rod.field.upload.moderation.title.invalid_quality':
		'Документ низкого качества и недействителен',
	'rod.field.upload.moderation.text':
		'Исправьте ошибки в документе и <a>повторите попытку</a>',
	'rod.error.forgot_password.not_found':
		'Восстановление пароля недоступно для этих пользователей. Если вы уверены в правильности введенных данных, обратитесь пожалуйста в службу поддержки.',
	'rod.error.forgot_password.not_coincide': 'Пароли не совпадают',
	'rod.error.forgot_password.wrong_data':
		'невозможно восстановить пароль с этими данными. Если вы уверены в корректности e-mail, обратитесь в поддержку',

	/*Release create */
	'rod.release_create.error.required': 'обязательное поле для ввода',
	'rod.release_create.error.cover_required': 'ошибка: отсутствует обложка',
	'rod.release_create.error.track_required':
		'ошибка: Релиз должен содержать хотя бы 1 трек',
	'rod.release_create.error.upload_only_one_track':
		'Для типа релиза {type} можно добавлять не более 1 трека',
	'rod.release_create.error.max_upload_count':
		'Превышено предельное количество треков на релиз',
	'rod.release_create.error.upload_universal_error':
		'Размер файла {name} слишком велик',
	'rod.release_create.error.upload_max_size_error':
		'Размер файла {name} слишком велик',
	'rod.release_create.error.upload_file_type_error':
		'Неверный формат. Принимаемые форматы аудио файлов: FLAC или WAV',
	'rod.release_create_snipped.date_format': 'Некорректное значение',
	'rod.release_create_snipped.required': 'обязательное поле',
	'rod.release_create_snipped.required_with': 'Пожалуйста, заполните оба поля',
	'rod.release_create_snipped.after_date':
		'Конец фрагмента должен быть позже начала',
	'rod.release_create.error.lyrics.required': 'обязательное поле для ввода',
	'rod.release_create.error.lyrics.max_length':
		'Максимальная длина 10000 символов',
	'rod.release_create.error.title.does not match':
		'Название трека не совпадает с названием релиза',
	'rod.release_create.error.subtitle.does not match':
		'Название версии не совпадает с написанием на первом шаге',

	/* Remove drafts*/
	'rod.error.failed': 'Невозможно удалить этот черновик',

	/*Contracts*/
	'rod.error.change_sign_type_contract':
		'Произошла ошибка. Пожалуйста, обратитесь в поддержку',

	//Admin FAQ//
	'rod.error.both_lang.required':
		'Вы ввели название рубрики только на одном языке. Пожалуйста, введите на всех языках.',
	'rod.error.upload_file': 'Пожалуйста загрузите документ',
};

export default errors;
