// Core
import { useContext, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Hooks
import { useClickOutside } from 'hooks';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';

// UI
import AdditionalRelease from '../AdditionalRelease/AdditionalRelease';
import { AutoWrapper, InfoTitle } from 'components';
import { FormInput } from 'components/Form/FormInput';
import { SwitchSave } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import { InfoButton } from 'components/Buttons';

// Utils
import { checkFieldIsValid } from '../utils/checkFieldIsValid';
import { formatSalesDate } from 'utils/formatSalesDate';
import { checkSnippetErrors } from '../utils/checkSnippetErrors';
import { showError } from 'validators/showError';
import { accounts } from 'services';

// Icons
import close_button from 'images/close_button.svg';

// Styles
import s from '../Tracks.module.css';

const TracksForm = ({
	releaseTypeId,
	transferRelease,
	lastSavePerson,
	changeMask,
	isSnippedRender,
	changeSnippet,
	errors,
	setErrors,
	changeField,
	handleForm,
	toogleMenu,
	selectPerson,
	setSelectPerson,
	recordingSave,
	toogleLastPerson,
	takeByLastPerson,
	handleOnBlurDateInput,
	countries,
	handleISRC,
	handleCatalogNumber,
	additionalReleases,
	setAdditionalReleases,
	setShowRangeModal,
	isMainValid,
	releaseData,
	disableSaveBtn,
}) => {
	const { isAdditionalReleaseEnable, isSlaPrivelegesForAccount } = useContext(
		AuthContext
	);
	const { lang } = useContext(LangContext);
	const containerTitleRef = useRef(null);
	const containerSubtitleRef = useRef(null);

	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [additionalErrors, setAdditionalErrors] = useState({});
	const [showTip, setShowTip] = useState(false);
	const [showSubtitleTip, setShowSubtitleTip] = useState(false);

	useClickOutside(containerTitleRef, () => setShowTip(false));
	useClickOutside(containerSubtitleRef, () => setShowSubtitleTip(false));

	const disableTitleCondition =
		releaseTypeId === 70 ||
		releaseTypeId === 51 ||
		releaseTypeId === 42 ||
		releaseTypeId === 43;
	const disableSubtitleCondition =
		(releaseTypeId === 70 ||
			releaseTypeId === 51 ||
			releaseTypeId === 42 ||
			releaseTypeId === 43) &&
		releaseData.subtitle;

	const checkOnValid = () => {
		return additionalReleases.every((additionalRelease) =>
			checkFieldIsValid(
				additionalRelease,
				setAdditionalErrors,
				transferRelease,
				isSlaPrivelegesForAccount
			)
		);
	};

	const onSaveHandler = async () => {
		if (!isAdditionalReleaseEnable && !additionalReleases.length)
			recordingSave(toogleMenu);

		if (isAdditionalReleaseEnable) {
			if (
				releaseTypeId !== 51 &&
				releaseTypeId !== 2 &&
				releaseTypeId !== 64 &&
				releaseTypeId !== 69 &&
				selectPerson.additional
			) {
				setShowRangeModal(true);
			} else {
				if (isMainValid(selectPerson, true)) return;

				setButtonDisabled(true);
				try {
					const savePromises = additionalReleases.map(
						async (additionalRelease) => {
							if (
								checkFieldIsValid(
									additionalRelease,
									setAdditionalErrors,
									transferRelease,
									isSlaPrivelegesForAccount
								)
							) {
								additionalRelease.sale_start_date = formatSalesDate(
									additionalRelease.sale_start_date
								);

								if (typeof additionalRelease.id === 'string') {
									await accounts.createAdditionalRelease(additionalRelease);
								} else {
									await accounts.updateAdditionalRelease(additionalRelease);
								}
							}
						}
					);

					await Promise.all(savePromises);
					setButtonDisabled(false);

					if (checkOnValid()) {
						recordingSave(toogleMenu);
					}
				} catch (error) {
					setErrors(error.response.data.errors);
					setButtonDisabled(false);
				}
			}
		}
	};

	return (
		<div className={s.form}>
			<img
				src={close_button}
				className={s.form__close}
				onClick={() => handleForm(toogleMenu)}
				alt=""
			/>

			{lastSavePerson && Object.keys(lastSavePerson).length !== 0 && (
				<div className={s.lastSave}>
					<div className={s.lastSave__switch}>
						<SwitchSave
							checked={takeByLastPerson}
							onChange={(_, c) => toogleLastPerson(c)}
						/>
					</div>
					<span>
						<FormattedMessage
							id={'rod.release.create.step.recording_info.form.save'}
						/>
					</span>
				</div>
			)}

			<span className={s.form__title} id="general">
				<FormattedMessage id={'rod.release.tab.main'} />
			</span>
			<div className={`${s.form__section} ${s.first__section}`}>
				<div className={s.input_leftCol} ref={containerTitleRef}>
					<FormInput
						type="muiInput"
						name={'title'}
						maxRows={4}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.name'}
							/>
						}
						disabled={disableTitleCondition}
						required
					/>
					{disableTitleCondition && (
						<InfoButton
							className={s.infoActive}
							style={{
								marginLeft: lang === 'ru' ? '8px' : '-28px',
							}}
							onClick={() => setShowTip(!showTip)}
						/>
					)}
					{showTip && (
						<div className={s.tip}>
							<FormattedMessage
								id={'rod.release.create.step.track.title.tip'}
							/>
						</div>
					)}
					{errors && Object.keys(errors).includes('title') && (
						<span className={s.Helper}>
							{typeof errors.title === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : errors?.title?.[0] === 'does not match' ? (
								<FormattedMessage
									id={`rod.release_create.error.title.does not match`}
								/>
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_rightCol} ref={containerSubtitleRef}>
					<FormInput
						type="muiInput"
						name={'subtitle'}
						maxRows={4}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.subtitle'}
							/>
						}
						disabled={disableSubtitleCondition}
					/>
					{disableSubtitleCondition && (
						<InfoButton
							className={s.infoActive}
							style={
								selectPerson.subtitle
									? {
											marginLeft: lang === 'ru' ? '0px' : '-14px',
									  }
									: {
											marginTop: '22px',
											marginLeft: lang === 'ru' ? '16px' : '0px',
									  }
							}
							onClick={() => setShowSubtitleTip(!showSubtitleTip)}
						/>
					)}
					{showSubtitleTip && (
						<div className={s.tip}>
							<FormattedMessage
								id={'rod.release.create.step.track.subtitle.tip'}
							/>
						</div>
					)}
					{errors && Object.keys(errors).includes('subtitle') && (
						<span className={s.Helper}>
							{typeof errors.subtitle === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : errors?.subtitle?.[0] === 'does not match' ? (
								<FormattedMessage
									id={`rod.release_create.error.subtitle.does not match`}
								/>
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>

				<div className={s.input_rightCol}>
					<FormInput
						type={'muiInputDate_v2'}
						readOnly={false}
						name={'created_date'}
						onChange={changeField}
						handleOnBlurDateInput={handleOnBlurDateInput}
						maxDate={true}
						errors={Object.keys(errors).includes('created_date') ? errors : {}}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.create_date'}
							/>
						}
						required
					/>
					{errors && Object.keys(errors).includes('created_date') && (
						<span className={s.Helper}>
							{
								<FormattedMessage
									id={`rod.error.${errors.created_date[0].rule}`}
								/>
							}
						</span>
					)}
				</div>

				<div className={s.input_leftCol}>
					<FormInput
						type={'muiAutocompleteMultipleGenres'}
						name={'genres'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						required
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.genre'}
							/>
						}
					/>
					{Object.keys(errors).includes('genres') && (
						<>
							{errors.genres[0].rule !== 'max_size' ? (
								<span className={s.Helper}>
									{
										<FormattedMessage
											id={`rod.release_create.error.required`}
										/>
									}
								</span>
							) : (
								<span className={s.Helper}>
									{errors.genres[0] && (
										<FormattedMessage
											id={'rod.error.max_size.genres'}
											values={{ value: errors.genres[0]?.value }}
										/>
									)}
								</span>
							)}
						</>
					)}
				</div>

				<div className={`${s.input_rightCol} ${s.input_country}`}>
					<FormattedMessage
						id={'rod.release.create.step.recording_info.label.create_country'}
					>
						{(placeholder) => (
							<FormInput
								type="country"
								name="created_country_id"
								onChange={changeField}
								errors={errors}
								data={selectPerson}
								placeholder={placeholder}
								items={countries}
								countries={countries}
								required
								customError
							/>
						)}
					</FormattedMessage>
					{errors && Object.keys(errors).includes('created_country_id') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>

				<div></div>

				<div
					className={
						releaseData.step.includes('h11')
							? `${s.input_leftCol} ${s.disabled}`
							: s.input_leftCol
					}
				>
					<InputMask
						mask={'aa-***-99-99999'}
						name={'isrc'}
						onChange={changeMask}
						errors={errors}
						value={selectPerson.isrc ? selectPerson.isrc : ''}
						disabled={
							(selectPerson.generate_isrc && !transferRelease) ||
							releaseData.step.includes('h11')
								? true
								: false
						}
					>
						{() => (
							<TextFieldInput
								variant="standard"
								type="muiInput"
								name={'isrc'}
								error={Object.keys(errors).length > 0 && errors['isrc']}
								value={selectPerson.isrc ? selectPerson.isrc : ''}
								label="ISRC"
								required
								disabled={
									(selectPerson.generate_isrc && !transferRelease) ||
									releaseData.step.includes('h11')
										? true
										: false
								}
							/>
						)}
					</InputMask>

					{errors && Object.keys(errors).includes('isrc') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>
				{!transferRelease && !releaseData.step.includes('h11') && (
					<AutoWrapper
						onClick={() => handleISRC(selectPerson?.generate_isrc)}
						check={selectPerson?.generate_isrc}
					/>
				)}

				<div className={s.input_leftCol}>
					<FormInput
						type="muiInput"
						name={'catalog_number'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						required={!transferRelease && !releaseData?.step?.includes('h11')}
						disabled={
							selectPerson.generate_catalog_number ||
							releaseData?.step?.includes('h11')
						}
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.catalog_number'}
							/>
						}
					/>
					{errors && Object.keys(errors).includes('catalog_number') && (
						<span className={s.Helper}>
							{<FormattedMessage id={`rod.release_create.error.required`} />}
						</span>
					)}
				</div>

				{!transferRelease && !releaseData?.step?.includes('h11') && (
					<AutoWrapper
						onClick={() =>
							handleCatalogNumber(selectPerson?.generate_catalog_number)
						}
						check={selectPerson.generate_catalog_number}
					/>
				)}
			</div>

			<span className={s.form__title}>
				<FormattedMessage id={'rod.release.create.step.tracks.contributors'} />
			</span>

			<div className={`${s.form__section} ${s.second__section}`}>
				<div className={s.input_leftCol}>
					<FormInput
						type="muiInput"
						name={'main_performer'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.release.label.main_executor'}
							/>
						}
						required
					/>
					{errors && Object.keys(errors).includes('main_performer') && (
						<span className={s.Helper}>
							{typeof errors.main_performer === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>

				<div className={s.input_rightCol}>
					<FormInput
						type="muiInput"
						name={'party_id'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage
								id={'rod.release.create.step.recording_info.label.label'}
							/>
						}
						required={true}
					/>
					{errors && Object.keys(errors).includes('party_id') && (
						<span className={s.Helper}>
							{typeof errors.party_id === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_leftCol}>
					<FormInput
						type="muiInput"
						name={'featured_artist'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={
							<FormattedMessage id={`rod.performance_work.featured_artist`} />
						}
					/>
					{errors && Object.keys(errors).includes('featured_artist') && (
						<span className={s.Helper}>
							{typeof errors.featured_artist === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
				<div className={s.input_rightCol}>
					<FormInput
						type="muiInput"
						name={'producer'}
						onChange={changeField}
						errors={errors}
						data={selectPerson}
						label={<FormattedMessage id={`rod.ref.roles.producer.summary`} />}
						required={!selectPerson.party_id}
					/>
					{errors && Object.keys(errors).includes('producer') && (
						<span className={s.Helper}>
							{typeof errors.producer === 'string' ? (
								<FormattedMessage id={`rod.release_create.error.required`} />
							) : (
								showError('max_length')(Object.values(errors)[0][0])
							)}
						</span>
					)}
				</div>
			</div>

			{isSnippedRender && (
				<>
					<InfoTitle
						title="rod.release.create.step.tracks.fragment.title"
						helpTitle="rod.release.create.step.tracks.fragment.help"
					/>
					<div className={`${s.form__section} ${s.third__section}`}>
						<div className={s.input}>
							<InputMask
								mask={'99:99:999'}
								maskChar={'0'}
								name={'snippet_start'}
								onChange={changeSnippet('snippet_start')}
								errors={errors}
								disabled={releaseData.step === 'h11'}
								value={
									selectPerson.snippet_start ? selectPerson.snippet_start : ''
								}
							>
								{() => (
									<TextFieldInput
										type="muiInput"
										variant="standard"
										name={'snippet_start'}
										required={releaseData.step !== 'h11'}
										errors={errors}
										disabled={releaseData.step === 'h11'}
										value={
											selectPerson.snippet_start
												? selectPerson.snippet_start
												: ''
										}
										label={
											<FormattedMessage
												id={'rod.release.create.step.tracks.fragment.start'}
											/>
										}
									/>
								)}
							</InputMask>
							{errors && Object.keys(errors).includes('snippet_start') && (
								<span className={s.Helper}>
									{checkSnippetErrors('snippet_start', errors)}
								</span>
							)}
						</div>
						<div className={s.input}>
							<InputMask
								mask={'99:99:999'}
								maskChar={'0'}
								name={'snippet_end'}
								disabled={true}
								errors={errors}
								value={selectPerson.snippet_end ? selectPerson.snippet_end : ''}
							>
								{() => (
									<TextFieldInput
										type="muiInput"
										disabled={true}
										variant="standard"
										name={'snippet_end'}
										errors={errors}
										value={
											selectPerson.snippet_end ? selectPerson.snippet_end : ''
										}
										label={
											<FormattedMessage
												id={'rod.release.create.step.tracks.fragment.end'}
											/>
										}
									/>
								)}
							</InputMask>
						</div>
					</div>
				</>
			)}

			{isAdditionalReleaseEnable &&
				!transferRelease &&
				(releaseTypeId === 51 ||
					releaseTypeId === 2 ||
					releaseTypeId === 64 ||
					releaseTypeId === 69 ||
					selectPerson.additional) &&
				!releaseData.step.includes('h11') && (
					<AdditionalRelease
						releaseTypeId={releaseTypeId}
						additionalReleases={additionalReleases}
						setAdditionalReleases={setAdditionalReleases}
						selectPerson={selectPerson}
						setSelectPerson={setSelectPerson}
						errors={errors}
						setErrors={setErrors}
						additionalErrors={additionalErrors}
						setAdditionalErrors={setAdditionalErrors}
						transferRelease={transferRelease}
					/>
				)}

			<button
				className={
					errors.snippet_start || errors.snippet_end
						? `${s.form__submit} ${s.form_submit_withError}`
						: `${s.form__submit}`
				}
				style={
					buttonDisabled || disableSaveBtn ? { opacity: 0.2 } : { opacity: 1 }
				}
				disabled={buttonDisabled || disableSaveBtn}
				onClick={onSaveHandler}
			>
				<FormattedMessage
					id={'rod.release.create.step.recording_info.form_save'}
				/>
			</button>
		</div>
	);
};

export default TracksForm;
