/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 16/12/2020, 15:04
 */
// eslint-disable-next-line
const modals = {
	'rod.modal.terms.header': 'пользовательское соглашение',
	'rod.modal.terms.text': `
		<h2>заголовок для абзаца</h2>
        <p>Проснувшись однажды утром после беспокойного сна, Грегор Замза обнаружил, что он у себя в постели превратился в страшное насекомое. Лежа на панцирнотвердой спине, он видел, стоило ему приподнять голову, свой коричневый, выпуклый, разделенный дугообразными чешуйками живот, на верхушке которого еле держалось готовое вот-вот окончательно сползти одеяло. Его многочисленные, убого тонкие по сравнению с остальным телом ножки беспомощно копошились у него перед глазами. «Что со мной случилось?» – подумал он. Ссылка</p>
        <h4>Список с буллитами:</h4>
		<ul>
		<li>What Type of User am I and What Privacy Terms are Applicable to Me?</li>
		<li>Privacy Terms for Subscribers</li>
		<li>Privacy Terms for Free Users</li>
		<li>Privacy Terms for Site Visitors</li>
		<li>Additional Privacy Terms for All Users</li>
		</ul>
		<h4>Список с цифрами:</h4>
		<ol>
		<li>What Type of User am I and What Privacy Terms are Applicable to Me?</li>
		<li>Privacy Terms for Subscribers</li>
		<li>Privacy Terms for Free Users</li>
		<li>Privacy Terms for Site Visitors</li>
		<li>Additional Privacy Terms for All Users</li>
		</ol>
    `,

	'rod.help.identifiers.modal.title':
		'International Standard Name Identifier (ISO 27729)',
	'rod.help.identifiers.modal.text':
		'Регистрация имен, псевдонимов и наименований юридических лиц происходит с внесением информации в Международный реестр имен и присвоением идентификатора ISNI. <br/> <br/> ISNI относится к международным стандартным идентификаторам, сертифицированным по стандартам ISO, таким как идентификаторы объектов, например ISBN, ISSN, ISRC, ISAN и др. <br/> <br/> Регистрация имени позволяет создателю и правообладателю интеллектуальной собственности получить репутацию. <br/> <br/> ISNI также устраняет противоречия и выделяет вас среди тех, кто обладает таким же именем. Идентификатор объединяет все возможные написания вашего имени (например, И. И. Петров или Иван Петров или Иван И. Петров) <br/> <br/> Регистрация имени ISNI помогает: Идентифицировать вас как создателей и правообладателей объектов интеллектуальной собственности. <br/> Отделить и дифференцировать часто встречающиеся имена и фамилии. <br/>  Собрать в одном месте разные варианты написания имени (Dostoyevsky/Dostoevski).  <br/> Упорядочить процесс получения и выплаты авторских сборов.  <br/> Сделать поиск более точным и увеличить вероятность обнаружения/открытия талантов.',
	'rod.modal.notify.admin.create_notification': 'Создание уведомления',
	'rod.modal.notify.admin.placeholder-header': 'Заголовок уведомления',
	'rod.modal.notify.admin.placeholder-body': 'Основной текст уведомления',
	'rod.modal.notify.admin.main-text': 'Основной текст',
	'rod.modal.notify.admin.send-all': 'Отправить всем',
	'rod.modal.notify.admin.send-to-whom': 'Кому',
	'rod.modal.notify.admin.status': 'Статус',
	'rod.modal.notify.admin.created-date': 'Дата создания',
	'rod.modal.notify.admin.confirm-yes': 'Да',
	'rod.modal.notify.admin.confirm-no': 'Нет',
	'rod.modal.notify.admin.confirm-question': 'Вы уверены?',
	'rod.modal.notify.already-read': 'Прочитано',
	'rod.modal.notify.not-read': 'Не прочитано',

	'rod.modal.release.withdrawal.rejected_title': 'снятие отклонено',
	'rod.modal.release.shipment.rejected_title': 'отгрузка отклонена',
	'rod.modal.release.withdrawal.error_title': 'ошибка снятия релиза',
	'rod.modal.release.shipment.error_title': 'ошибка отгрузки релиза',
	'rod.modal.release.withdrawal.outlets_failed_title':
		'не удалось снять релиз с некоторых витрин',
	'rod.modal.release.shipment.outlets_failed_title':
		'часть витрин не отгрузились',

	'rod.modal.release.withdrawal.rejected_text':
		'не указаны метаданные релиза. Вы можете посмотреть детали в табах карточки релиза. Исправьте данные и попробуйте снова',
	'rod.modal.release.shipment.rejected_text':
		'не указаны метаданные релиза. Вы можете посмотреть детали в табах карточки релиза. Исправьте данные и попробуйте снова',
	'rod.modal.release.shipment.error_text':
		'невозможно отгрузить релиз на витрины: {outlets}. За уточнениями обратитесь в техподдержку или посмотрите ответы в разделе FAQ ',
	'rod.modal.release.withdrawal.error_text':
		'невозможно снять релиз с витрин: {outlets}. За уточнениями обратитесь в раздел FAQ или в тех. поддержку',
	'rod.modal.release.shipment.outlets_failed_text':
		'невозможно отгрузить релиз на витрины: {outlets}. За уточнением причин, обратитесь в в тех.поддержку',
	'rod.modal.release.withdrawal.outlets_failed_text':
		'невозможно снять релиз с витрин: {outlets}. За уточнением причин, обратитесь в тех.поддержку',
	'rod.modal.payments.wrong_currency_title': 'указаны не все данные',
	'rod.modal.payments.wrong_currency_text':
		'банковские данные и валюта контракта не совпадают, либо заполнены не все данные. Пожалуйста, пройдите на следующий шаг, укажите корректные данные или обратитесь в тех. поддержку',
	'rod.modal.payments.wrong_currency_action': 'далее',

	'rod.modal.release.delete.file.title':
		'вы действительно хотите удалить трек?',
	'rod.modal.release.delete.file.text': 'данное действие нельзя будет отменить',
	'rod.modal.release.delete.last_file.text':
		'релиз должен содержать хотя бы одну фонограмму',
	'rod.modal.release.delete.last_file.action': 'понятно',
};

export default modals;
