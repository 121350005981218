// Core
import React, { useState, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import { compose } from 'recompose';

// Utils
import { clearLocalStorageItems } from './utils/clearLocalStorageItems';
import { withAuth, withLang, withUI, withRoot } from 'hocs';

// UI
import Socials from './Socials/Socials';

// Styles
import styles from './NavBar.module.css';

const NavBar = (props) => {
	const location = useLocation();
	const [isChevronOpen, setIsChevronOpen] = useState(false);
	const [route, setRoute] = useState('');

	const {
		UIContext: {
			isShowNavAndButtons,
			isAdminLayoutShow,
			isFaqEnabled,
			selectRepertoire,
			isReportsEnable,
			isDisplayingStatistics,
			isManagementDisable,
			isDisplayingContracts,
			isHideAdminStat,
			setIsTransferRelease,
		},
		authContext: { isLegacy },
		rootContext: { getAccountStatisticCheck },
	} = props;

	const isJoin = props.authContext.isJoin;
	const id = localStorage.getItem('accountId');
	const { pathname } = useLocation();
	const length = pathname.split('/').length;
	const lang = localStorage.getItem('lang');
	const isNoContract = localStorage.getItem('no_contract');

	const handleOnClick = () => {
		setIsChevronOpen((prev) => !prev);
	};

	const removeIsTransferRelease = () => {
		localStorage.removeItem('isTransferRelease');
		setIsTransferRelease(false);
	};

	const onResetHandler = () => {
		clearLocalStorageItems();
		removeIsTransferRelease();
	};

	const setRouteData = async () => {
		try {
			if (isJoin) {
				const { data: res } = await getAccountStatisticCheck(id);
				const { statistic_types } = res;
				if (!statistic_types.length || statistic_types.length === 2) {
					setRoute('');
				}
				if (statistic_types.length === 1) {
					if (statistic_types.includes('copyright')) {
						setRoute('/compositions');
					} else if (statistic_types.includes('allied')) {
						setRoute('/phonograms');
					}
				}
			}
		} catch (error) {
			console.error('Error fetching account statistics:', error);
		}
	};

	useEffect(() => {
		const fetchRouteData = async () => {
			try {
				await setRouteData();
			} catch (error) {
				console.error('Error fetching account statistics:', error);
			}
		};

		if (id) {
			fetchRouteData();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<nav className={styles.NavBar}>
			{!isAdminLayoutShow ? (
				<>
					{isShowNavAndButtons && isJoin && !isLegacy && !isNoContract ? (
						<ul className={styles.UserMenu}>
							<li>
								<NavLink
									activeClassName={styles.activeLink}
									to={`/accounts/${id}/dashboard`}
									onClick={onResetHandler}
									exact
								>
									<FormattedMessage id={'rod.navbar.main'} />
								</NavLink>
							</li>

							<li>
								<NavLink
									activeClassName={styles.activeLink}
									to={`/repertoire/${
										selectRepertoire ? selectRepertoire : 'drafts'
									}`}
									onClick={onResetHandler}
									isActive={() =>
										location.pathname.split('/').includes('repertoire')
									}
								>
									<FormattedMessage id={'rod.navbar.repertoire'} />
								</NavLink>
							</li>

							{isDisplayingContracts && (
								<li>
									<NavLink
										activeClassName={styles.activeLink}
										to="/treaties"
										onClick={onResetHandler}
									>
										<FormattedMessage id={'rod.navbar.treaties'} />
									</NavLink>
								</li>
							)}

							{isJoin && isDisplayingStatistics && (
								<li>
									<NavLink
										isActive={() => {
											return ['bydate', 'byterritory', 'statistic-reports'].includes(
												pathname.split('/')[length - 1]
											);
										}}
										activeClassName={styles.activeLink}
										to={`/accounts/${id}/statistic/bydate${route}`}
										onClick={onResetHandler}
									>
										<FormattedMessage id={'rod.navbar.statistic'} />
									</NavLink>
								</li>
							)}

							{isReportsEnable && (
								<li>
									<NavLink
										activeClassName={styles.activeLink}
										to={`/accounts/${id}/reports`}
										isActive={() => location.pathname.indexOf('/reports') !== -1}
										onClick={onResetHandler}
										exact
									>
										<FormattedMessage id={'rod.navbar.reports'} />
									</NavLink>
								</li>
							)}
						</ul>
					) : (
						<div className={styles.UserEmpty}></div>
					)}
				</>
			) : (
				<ul className={styles.UserMenu}>
					{!isManagementDisable && (
						<li className={styles.HandlingWrapper}>
							<div
								className={styles.HandlingHeadingWrapper}
								onClick={handleOnClick}
							>
								<span>
									<FormattedMessage id={'rod.navbar.handling'} />
								</span>
								{isChevronOpen ? (
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M7 14.5L12 9.5L17 14.5H7Z" fill="#C4C4C4" />
									</svg>
								) : (
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#C4C4C4" />
									</svg>
								)}
							</div>
							{isChevronOpen && (
								<div
									className={styles.SubHandlingWrapper}
									font="--gilroy-Medium-20"
								>
									<ul className={styles.subHandlingList}>
										<li className={styles.subHandlingItem}>
											<NavLink
												activeClassName={styles.activeLink}
												to={`/admin/v2/handling_drafts`}
												isActive={() =>
													location.pathname.indexOf('handling_drafts') !== -1
												}
												exact
											>
												<FormattedMessage id={'rod.navbar.handling.drafts'} />
											</NavLink>
										</li>
										<li className={styles.subHandlingItem}>
											<NavLink
												activeClassName={styles.activeLink}
												to={`/admin/v2/handling_release`}
												isActive={() =>
													location.pathname.indexOf('handling_release') !== -1
												}
												exact
											>
												<FormattedMessage id={'rod.navbar.handling.release'} />
											</NavLink>
										</li>
									</ul>
								</div>
							)}
						</li>
					)}
					<li>
						<NavLink
							activeClassName={styles.activeLink}
							to={`/admin/v2/accounts`}
							isActive={() => location.pathname.indexOf('v2/accounts') !== -1}
							exact
						>
							<FormattedMessage id={'rod.navbar.accounts'} />
						</NavLink>
					</li>
					{isDisplayingStatistics && !isHideAdminStat && (
						<li>
							<NavLink
								activeClassName={styles.activeLink}
								to={`/admin/v2/statistics_service`}
								isActive={() =>
									location.pathname.indexOf('statistics_service') !== -1
								}
								exact
							>
								<FormattedMessage id={'rod.navbar.admin.statistics_service'} />
							</NavLink>
						</li>
					)}
					<li>
						<NavLink
							activeClassName={styles.activeLink}
							to={`/admin/v2/notifications`}
							isActive={() => location.pathname.indexOf('notifications') !== -1}
							exact
						>
							<FormattedMessage id={'rod.notification.title'} />
						</NavLink>
					</li>
					<li>
						<NavLink
							activeClassName={styles.activeLink}
							to={`/admin/v2/articles`}
							isActive={() => location.pathname.indexOf('articles') !== -1}
							exact
						>
							<FormattedMessage id={'rod.admin.articles'} />
						</NavLink>
					</li>
					{isReportsEnable && (
						<li>
							<NavLink
								activeClassName={styles.activeLink}
								to={`/admin/v2/reports`}
								isActive={() => location.pathname.indexOf('reports') !== -1}
								exact
							>
								<FormattedMessage id={'rod.navbar.reports'} />
							</NavLink>
						</li>
					)}
				</ul>
			)}
			<ul className={styles.faq}>
				{!isAdminLayoutShow &&
					isShowNavAndButtons &&
					isJoin &&
					!isLegacy &&
					isFaqEnabled &&
					!isNoContract && (
						<li>
							<NavLink
								activeClassName={styles.activeLink}
								to={`/faq`}
								isActive={() => location.pathname.split('/').includes('faq')}
								exact
							>
								<FormattedMessage id={'rod.nav.link.faq'} />
							</NavLink>
						</li>
					)}

				{!isAdminLayoutShow &&
					isShowNavAndButtons &&
					isJoin &&
					!isLegacy &&
					!isNoContract &&
					process.env.REACT_APP_CLIENT === 'dgtal' && (
						<li>
							<a
								activeClassName={styles.activeLink}
								href={
									lang === 'en'
										? 'mailto:DGTal.help@gmail.com'
										: 'mailto:legal.help.bfs@gmail.com'
								}
							>
								<FormattedMessage id={'rod.nav.link.support'} />
							</a>
						</li>
					)}
			</ul>
			{process.env.REACT_APP_CLIENT === 'mts' && <Socials />}
			<div
				className={styles.Copyright}
				style={
					process.env.REACT_APP_CLIENT === 'mts'
						? { paddingTop: '0', paddingBottom: '16px' }
						: {}
				}
			>
				<FormattedHTMLMessage id={'rod.nav.link.h11'} />
			</div>
		</nav>
	);
};

export default compose(withAuth, withLang, withUI, withRoot)(NavBar);
